import { createContext, RefObject } from 'react'
import { MainPageData, PositionAndSize, SectionData, SupportedLanguages } from '../types/types'

interface ContextProps {
  isMobile: boolean
  locale: SupportedLanguages
  pageData: MainPageData
  sections: SectionData
  anchors: {
    [anchorName: string]: PositionAndSize
  }
  updateAnchor: (name: string, position: PositionAndSize) => void
  windowHeight: number
}

const LandingPageContext = createContext<Partial<ContextProps>>({})

export default LandingPageContext
