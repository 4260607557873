import { useEffect, useState, useRef } from 'react'
import { Sections, MenuKeys } from '../types/types'

export const SectionHeights = {
  [Sections.mission]: {
    desktop: 2.5,
    mobile: 1.8,
  },
  [Sections.qpad]: {
    desktop: 1.3,
    mobile: 1.3,
  },
  [Sections.howTo]: {
    desktop: 2,
    mobile: 3,
  },
  [Sections.power]: {
    desktop: 4,
    mobile: 4,
  },
  [Sections.aboutUs]: {
    desktop: 4.2,
    mobile: 4.2,
  },
  [Sections.contact]: {
    desktop: 0.5,
    mobile: 0.5,
  },
  [Sections.careers]: {
    desktop: 0.0,
    mobile: 0.0,
  },
} as const

type SectionHeightDetails = {
  [key in MenuKeys]: {
    height: number
    startsAt: number
  }
}

const getSectionStartPoint = (isMobile: boolean, sectionKey: MenuKeys) => {
  return Object.keys(SectionHeights).reduce(
    (acc, key: MenuKeys) => {
      return key === sectionKey || acc.found
        ? { ...acc, found: true }
        : { ...acc, point: acc.point + (isMobile ? SectionHeights[key].mobile : SectionHeights[key].desktop) }
    },
    { found: false, point: 0 }
  ).point
}

const generateResponsiveSections = (isMobile: boolean): Partial<SectionHeightDetails> =>
  Object.values(Sections).reduce(
    (acc, sectionKey) => ({
      ...acc,
      [sectionKey]: {
        height: isMobile ? SectionHeights[sectionKey].mobile : SectionHeights[sectionKey].desktop,
        startsAt: getSectionStartPoint(isMobile, sectionKey),
      },
    }),
    {}
  )

const MOBILE_RESPONSIVE_SECTIONS = generateResponsiveSections(true)
const DESKTOP_RESPONSIVE_SECTIONS = generateResponsiveSections(false)

export const responsiveSections = (isMobile: boolean = false) =>
  isMobile ? MOBILE_RESPONSIVE_SECTIONS : DESKTOP_RESPONSIVE_SECTIONS

export const getAccumulatedHeight = (heights: Array<number>, indexes: Array<number>) => {
  return heights.reduce((acc, height, i) => (indexes.includes(i) ? acc + height : acc), 0)
}

export const getSectionHeights = (windowHeight: number, isMobile: boolean = false) => {
  return Object.values(Sections).map(
    // @ts-ignore
    section => windowHeight * (isMobile ? SectionHeights[section].mobile : SectionHeights[section].desktop)
  )
}

export const MenuHeights = {
  mobile: 72,
  desktop: 136,
}

/** Utility hook used to determine the scroll position on the viewport. */
const useScrollPosition = (windowHeight: number) => {
  const frameRequestId = useRef(0)
  const [scrollRatio, setScrollRatio] = useState(0)

  const updateScrollPositions = () => {
    setScrollRatio(window.pageYOffset / windowHeight)
  }

  const handleScrollEvent = () => {
    frameRequestId.current = window.requestAnimationFrame(updateScrollPositions)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleScrollEvent()
      window.addEventListener('scroll', handleScrollEvent)
    }
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
      if (frameRequestId.current) {
        window.cancelAnimationFrame(frameRequestId.current)
      }
    }
  }, [windowHeight])

  return scrollRatio
}

export default useScrollPosition
