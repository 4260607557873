import { useEffect, useState, useContext } from 'react'
import GlobalStateContext from '../state'
import Breakpoints from './breakpoints'

const isWindow = () => typeof window !== 'undefined'

/** Returns responsiveness information to the components using it.
 */
const useResponsive = () => {
  const { isMobile: isMobileCtx } = useContext(GlobalStateContext)
  const defaultWidthForContext = isMobileCtx ? Breakpoints.MAX_MOBILE - 1 : Breakpoints.MAX_DESKTOP
  const [windowWidth, setWindowWidth] = useState(isWindow() ? window.innerWidth : defaultWidthForContext)
  useEffect(() => {
    const updateSectionWidth = () => {
      if (isWindow() && window.innerWidth !== windowWidth) {
        setWindowWidth(window.innerWidth)
      }
    }

    updateSectionWidth()

    if (isWindow()) {
      window.addEventListener('resize', updateSectionWidth)
    }

    return () => window.removeEventListener('resize', updateSectionWidth)
  }, [windowWidth])

  const isMobile = windowWidth <= Breakpoints.MAX_MOBILE
  return { isMobile, windowWidth, isVisible: isMobile === isMobileCtx }
}

export default useResponsive
