interface Identifiable {
  id: string
}

interface HumanIdentifiable {
  humanId: string
}

interface CreateStamp extends Identifiable {
  createUserId: String
  createTime: Date
}

interface PaginatedList<T> {
  nodes: Array<T>
}

interface ContentfulImageData {
  url: string
  details: {
    image: {
      width: number
    }
  }
}

interface ContentfulImage {
  title: string
  file: ContentfulImageData
}

interface ContentfulVideo {
  title: string
  file: {
    url: string
    contentType: string
  }
}

interface StepData {
  step: number
  title: string
  description: string
}

interface ContentfulSectionData {
  position: number
  title: string
  description: {
    description: string
  }
  data?: Array<StepData>
}

interface ContentfulStayInTouchSectionData {
  signUpTitle: string
  signUpDescription: string
  downloadTitle: string
  downloadDescription: string
  iosUrl: string
  googleUrl: string
  googleMarketUrl: string
}

interface ImageVariant {
  url: string
  width: number
}

interface ImagesMap {
  [key: string]: Array<ImageVariant>
}

interface VideoData {
  url: string
  contentType: string
}

interface VideosMap {
  [key: string]: VideoData
}

interface SectionData {
  title: string
  description: string
  data?: Array<StepData>
}

interface PositionAndSize {
  top: number
  left: number
  width?: number
  height?: number
}

const Sections = {
  mission: 'mission',
  qpad: 'qpad',
  howTo: 'howTo',
  power: 'power',
  aboutUs: 'aboutUs',
  contact: 'contact',
  careers: 'careers',
} as const

type MenuKeys = keyof typeof Sections
type MenuValues = typeof Sections[MenuKeys]

type SupportedLanguages = 'en' | 'es'
type MenuEntries = { [key in MenuKeys]: string }

interface MainPageRawData {
  allContentfulMenus: PaginatedList<MenuEntries>
  logo: PaginatedList<ContentfulImage>
  sections: PaginatedList<ContentfulSectionData>
  stayInTouchSection: PaginatedList<ContentfulStayInTouchSectionData>
  spheres: PaginatedList<ContentfulImage>
  stepImages: PaginatedList<ContentfulImage>
  background: PaginatedList<ContentfulImage>
  qpad: PaginatedList<ContentfulImage>
  appStore: PaginatedList<ContentfulImage>
  googlePlay: PaginatedList<ContentfulImage>
  arrow: PaginatedList<ContentfulImage>
  founders: PaginatedList<ContentfulImage>
  logoVideo: PaginatedList<ContentfulVideo>
  powerVideo: PaginatedList<ContentfulVideo>
}

interface CartPageData {
  cartCount: number
}

interface ShopPageData {
  logo: ContentfulImageData
  locale: SupportedLanguages
  cartCount: number
  setCartCount: (cc: number) => void
  cartId: string
}

interface PurchaseSuccessPageRawData {
  logo: PaginatedList<ContentfulImage>
  purchaseSuccess: PaginatedList<ContentfulImage>
  cart: PaginatedList<ContentfulImage>
}

interface MainPageData {
  menus: MenuEntries
  logo: ContentfulImageData
  sections: Array<SectionData>
  images: ImagesMap
  videos: VideosMap
  stayInTouchSection: ContentfulStayInTouchSectionData
}

interface DescriptionItemRichText {
  descriptionItems: any
}

interface ProductType {
  secureName: string
  name: string
  description: string
  childContentfulProductTypeDescriptionItemsRichTextNode: DescriptionItemRichText
  image: ContentfulImage
  displayShop: boolean
}

interface ConsentForm {
  name: string
  content: string
}

export {
  CreateStamp,
  Identifiable,
  HumanIdentifiable,
  PaginatedList,
  PositionAndSize,
  ContentfulImage,
  ContentfulVideo,
  ImageVariant,
  ImagesMap,
  MainPageData,
  MainPageRawData,
  ShopPageData,
  CartPageData,
  PurchaseSuccessPageRawData,
  MenuEntries,
  MenuKeys,
  SupportedLanguages,
  Sections,
  SectionData,
  VideosMap,
  ProductType,
  ContentfulStayInTouchSectionData,
  ConsentForm,
}
