import React, { ReactNode, useEffect, useState } from 'react'
import useResponsive from '../../utils/useResponsive'
import { getSectionHeights } from '../../utils/useScrollPosition'
import SEO from '../seo'
import styles from './Layout.module.css'

interface IProps {
  children: ReactNode
  onLayoutMounted: (windowHeight: number) => void
  windowHeight?: number
}

const Layout = ({ children, onLayoutMounted, windowHeight }: IProps) => {
  const [isMounted, setIsMounted] = useState(false)
  const { isVisible, isMobile } = useResponsive()

  useEffect(() => {
    setIsMounted(true)
    onLayoutMounted(window.innerHeight)
  }, [])
  return (
    ((!isMounted && !isMobile) || (isMounted && isVisible)) && (
      <>
        <SEO title="Qvin" />
        <div className={styles.container}>
          <div
            className={styles.layout}
            css={{
              height: `${
                windowHeight ? getSectionHeights(windowHeight, isMobile).reduce((acc, height) => acc + height, 0) : 100
              }${windowHeight ? 'px' : '%'}`,
            }}
          >
            {children}
          </div>
        </div>
      </>
    )
  )
}

export default Layout
